export default {
  watch: {
    '$store.state.user.customerDetails.contract': {
      async handler(customerContracts) {
        if (customerContracts) {
          const contractTypes = [];
          if (Array.isArray(customerContracts)) {
            for (const contract of customerContracts) {
              contractTypes.push(contract.contractType);
            }
          } else {
            contractTypes.push(customerContracts?.contractType);
          }

          await this.$store.dispatch('device/GET_DEVICE_TYPES', contractTypes);
        }
      },
      immediate: true,
    },
  },
};
